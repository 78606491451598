import { Typography, Grid, Slider, Paper, Chip, Button, Divider} from '@mui/material';
import { useState, useContext } from 'react';
import { HeatingDataContext } from './App';

function LevelDisplay() {
    const { heatingData, triggerGetDatas, dayType } = useContext(HeatingDataContext);
    let timeout;
    const [sliderValue, setSliderValue] = useState(0);
    const [showSlider, setShowSlider] = useState(false);
    const formatTime = (value) => {
        const hours = Math.floor(value / 6);
        const minutes = (value % 6) * 10;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };
    const formatTimeHourMinute = (value) => {
        const hours = Math.floor(value / 6);
        const minutes = (value % 6) * 10;
        return [hours,minutes];
    };
    const handleFinalValue = (value) => {
        console.log("Final slider value:", value[0], value[1]);
        fetch(`https://api.apexinformatika.hu/addcolumn/${dayType}/${value[0]}/${value[1]}`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(data => { 
            console.log("Response from server:", data);
        setShowSlider(false);
        triggerGetDatas();
        })
        .catch(error => {
            setShowSlider(false);
            console.error("Error sending data:", error);
        });

    };
    const handleSliderChange = (id, level) => {

            console.log(`Item ID: ${id}, Changed Value: ${level}`);
    
            fetch(`https://api.apexinformatika.hu/day-types/${id}/${level}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => response.json())
            .then(data => { 
                console.log("Response from server:", data);
                triggerGetDatas();
            })
            .catch(error => {
                console.error("Error sending data:", error);
            });
    };
    
    const [toBeDeleted, setToBeDeleted] = useState(null);  // Initialize with null

const handleChipClick = (id) => {
    if (toBeDeleted === id) {
        // If chip is already in the "to-be-deleted" state, trigger the delete API call
        fetch(`https://api.apexinformatika.hu/day-types/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("Deleted:", data);
            setToBeDeleted(null);
            triggerGetDatas();
        })
        .catch(error => {
            console.error("Error deleting data:", error);
        });
    } else {
        setToBeDeleted(id);
    }
}
        return (
            <Paper elevation={3} style={{ marginTop: '16px', padding: '16px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <Typography align="center" variant="h6">Radiator Levels throughout the Day</Typography>
                <Grid container wrap="nowrap" alignItems="center">
                    {heatingData.map((item, index) => {
    const prevItem = heatingData[index - 1];
    const prespawn = prevItem ? null : item.hour + 1;  
    const nextItem = heatingData[index + 1];
    const nextHour = nextItem ? nextItem.hour - 1 : 23;
    const spawn = nextHour - item.hour;
                        const time = `${item.hour.toString().padStart(2, '0')}:${item.minute.toString().padStart(2, '0')}`;
                        const isPast = (new Date().getHours() > item.hour) || 
                                    (new Date().getHours() === item.hour && new Date().getMinutes() >= item.minute);
                        return (
                            <>
                            {Array.from({ length: prespawn }).map((_, index) => (
                            <Grid item key={index} style={{ marginRight: '10px', width: '80px' }}>
                                .
                            </Grid>
                                ))}
                            <Grid item key={time} style={{ width: '90px' }}>
                                <Typography style={{ textAlign: 'center', color: isPast ? 'gray' : 'black' }}>
                                <Chip
                                    label={time}
                                    clickable
                                    onClick={() => handleChipClick(item.id)}
                                    color={toBeDeleted === item.id ? "error" : "default"}  // Check if current item's id matches toBeDeleted
                                    deleteIcon={toBeDeleted === item.id && <Typography color="error">X</Typography>}  // Same here
                                />
                                <p></p>
                                </Typography>
                                <Slider
                                    orientation="vertical"
                                    defaultValue={item.level}
                                    step={1}
                                    marks
                                    min={0}
                                    max={5}
                                    valueLabelDisplay="on"
                                    style={{ color: isPast ? 'gray' : 'primary', height: '200px' }}
                                    onChangeCommitted={(e, value) => handleSliderChange(item.id, value)}
                                />
                            </Grid>
                                {Array.from({ length: spawn }).map((_, index) => (
                            <Grid item key={index} style={{ marginRight: '10px', width: '80px' }}>
                                .
                            </Grid>
                                ))}

                            </>
                        );
                    })}

    <Grid style={{ marginRight: '10px', width: '100px' }}>

    </Grid>

                </Grid>
                <Divider></Divider>
                {!showSlider && <Button onClick={() => setShowSlider(true)}>Add new change point</Button>}
            
            {showSlider && (
                <Slider
                    style={{ marginTop: '20px', width: '90%' }}
                    defaultValue={0}
                    step={1}
                    marks
                    min={0}
                    max={143}
                    valueLabelDisplay="on"
                    valueLabelFormat={formatTime}
                    onChange={(e, newValue) => setSliderValue(newValue)}
                    onChangeCommitted={(e, finalValue) => {
                        const formattedValue = formatTimeHourMinute(finalValue);
                        handleFinalValue(formattedValue);
                    }}
                />
            )}
            </Paper>
        );
}

export default LevelDisplay;
