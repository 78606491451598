import { NavigateNext, Thermostat, CalendarToday } from '@mui/icons-material';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import AccessTime from '@mui/icons-material/AccessTime';
import EventNoteIcon  from '@mui/icons-material/EventNote';
import { useState } from 'react';
import { Typography, Divider, Grid , Button} from '@mui/material';
import { HeatingDataContext } from './App';


import { useContext } from 'react';

function Main() {
  const { dayOfWeek, setDayOfWeek, dayType, currentLevel } = useContext(HeatingDataContext);
    const hungarianDayNames = ["", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat","vasárnap"];

    const handleNextClick = () => {
        const newDay = dayOfWeek + 1;   
        setDayOfWeek(newDay > 7 ? 1 : newDay);
      };
    return(
<>  
        <Divider className="customDivider" ></Divider>
        <Typography variant="h5" align="center">
            <CalendarToday /> {new Date().toLocaleDateString()}
        </Typography>

        <Typography variant="h6" align="center">
            <AccessTime /> {new Date().getHours().toString().padStart(2, '0')}:{new Date().getMinutes().toString().padStart(2, '0')}
        </Typography>
        <Typography variant="h6" align="center">
        
            <Button style={{width: '30%'}} color="inherit" onClick={handleNextClick}>{hungarianDayNames[dayOfWeek]} {dayOfWeek == new Date().getDay() ? '(ma)' : null}<NavigateNext  /> </Button>
        </Typography>
        
        <Divider className="customDivider" ></Divider>
<Grid container spacing={2} alignItems="center" justifyContent="center">
  <Grid item xs={3} style={{ textAlign: 'right' }}>
    <Thermostat style={{ fontSize: 40 }} />
    <Typography variant="h6">23°C</Typography>
  </Grid>
  <Grid item xs={1} />
  <Grid item xs={3} style={{ textAlign: 'left' }}>
    <ModelTrainingIcon style={{ fontSize: 40 }} />
    <Typography variant="h6">{currentLevel}</Typography>
  </Grid>
  <Grid item xs={3} style={{ textAlign: 'left' }}>
    <EventNoteIcon style={{ fontSize: 40 }} />
    <Typography variant="h6">{dayType}</Typography>
  </Grid>
</Grid>
</>
    )


}


export default Main;