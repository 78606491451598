import React, { createContext,useContext,useEffect, useState } from 'react';
import TopNavbar from './TopNavbar';
import LevelDisplay from './LevelDisplay';
import './App.css';
import DayTypeSelector from './DayTypeSelector';
import Main from './Main';
  export const HeatingDataContext = createContext();

  
function App() {


const [dayType, setDayType] = useState('');
const [currentLevel, setCurrentLevel] = useState(-1);
    const [page, setPage] = useState('level');
    const [ heatingData, setHeatingData] = useState([]);
    const heatColors = ['lightblue', 'blue', 'deepskyblue', 'orange', 'darkorange', 'red'];
    const [dayOfWeek, setDayOfWeek] = useState(new Date().getDay());
   

    const triggerGetDatas = () => {
        getDatas();
    }

    function getDatas() {
        fetch(`https://api.apexinformatika.hu/currentlevel`)
        .then(res => res.json())
        .then(data => {
            setCurrentLevel(data.level);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
        fetch(`https://api.apexinformatika.hu/day/${dayOfWeek}`)
            .then(res => res.json())
            .then(data => {
                const type = data[0]?.type;
                setDayType(type)
                if (type) {
                    fetch(`https://api.apexinformatika.hu/heating/${type}`)
                        .then(res => res.json())
                        .then(data => setHeatingData(data))
                        .catch(err => console.error('Error fetching heating data:', err));
                }
            })
            .catch(err => console.error('Error fetching day data:', err));

    }





    useEffect(() => {
        getDatas();
      
    }, [dayOfWeek]);

    
    function isCurrentTimeframe(item, nextItem) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
  
      const itemTimeInMinutes = item.hour * 60 + item.minute;
      const nextItemTimeInMinutes = nextItem ? (nextItem.hour * 60 + nextItem.minute) : 24 * 60; 
  
      return itemTimeInMinutes <= (currentHour * 60 + currentMinute) && (currentHour * 60 + currentMinute) <= nextItemTimeInMinutes;
  }
  

    return (
        <div className="App">
        
            
            <header className="App-header">
            <TopNavbar setPage={setPage} />

            <HeatingDataContext.Provider value={{ heatingData, setHeatingData, dayOfWeek, setDayOfWeek, triggerGetDatas, dayType, setPage, currentLevel }}>

        <Main></Main>
        {(() => {
    switch (page) {
        case 'level':
            return <LevelDisplay />;
        case 'daytypes':
            return <DayTypeSelector />;
        default:
            return null; 
    }
})()}

</HeatingDataContext.Provider>

            </header>
        </div>
    );
}

export default App;
