import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, FormControl, Select, MenuItem, Button, TextField } from '@mui/material';

function DayTypeSelector() {
    const [dayTypes, setDayTypes] = useState([]);
    const [daysData, setDaysData] = useState([]);
    const [newDayType, setNewDayType] = useState(null);

    const onChange = (event) => {
        setNewDayType(event.target.value)
       }
    function fetchTypesAndDays() {
          
          fetch('https://api.apexinformatika.hu/types')
          .then(response => response.json())
          .then(data => {
              const types = data.map(item => item.type); 
              setDayTypes(types);
          })
          .catch(error => {
              console.error("Error fetching day types:", error);
          });

      fetch('https://api.apexinformatika.hu/days')
          .then(response => response.json())
          .then(data => {
              setDaysData(data);
          })
          .catch(error => {
              console.error("Error fetching day data:", error);
          });
    }
    function addNewDayType() {

        if(!newDayType)
        {
            setNewDayType(' ');
            return;
        }

    fetch(`https://api.apexinformatika.hu/day-types/new/${newDayType}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Response from server:", data);
        setNewDayType(null);
        fetchTypesAndDays();
    })
    .catch(error => {
        console.error("Error sending data:", error);
    });


}
    useEffect(() => {
        fetchTypesAndDays();
    }, []);

    const handleDayTypeChange = (dayId, event) => {
        const newType = event.target.value;
    
        // Update the frontend immediately for a smooth user experience
        const updatedDays = daysData.map(day => {
            if (day.id === dayId) {
                return { ...day, type: newType };
            }
            return day;
        });
        setDaysData(updatedDays);
    
        // Send a request to the backend to update the data there
        fetch(`https://api.apexinformatika.hu/days/${dayId}/${newType}`, {
            method: 'PUT',
        })
        .then(response => response.json())
        .then(data => {
            console.log("Day type updated:", data);
        })
        .catch(error => {
            console.error("Error updating day type:", error);
    
            fetchTypesAndDays();
        });
    };
    

    return (
        <Paper elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
            <Typography align="center" variant="h6">Select Day Types</Typography>
            <Grid container spacing={3}>
                {daysData.map((day) => (
                    <Grid item xs={12} md={6} lg={4} key={day.id}>
                        <Typography variant="h6">{day.name}</Typography>
                        <FormControl fullWidth variant="outlined">
                            <Select
                                value={day.type}
                                onChange={(event) => handleDayTypeChange(day.id, event)}
                            >
                                {dayTypes.map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
<Grid item xs={12} md={6} lg={4} key={0}>
{newDayType ? <TextField onChange={onChange} id="outlined-basic" label="Nap típus megnevezése" variant="outlined" value={newDayType}/> : ' '}
<Button onClick={()=> addNewDayType()}>Add new day type</Button>
</Grid>
            </Grid>
        </Paper>
    );
}

export default DayTypeSelector;
