import { Toolbar, Button } from '@mui/material';
import logo from "./logo.png"
function TopNavbar({ setPage }) {
    return (
        <>
            <Toolbar className="toolbar-flex">
                <img className="logo" src={logo} alt="react logo"  />
                <div className="button-container">
                <Button color="inherit" onClick={() => setPage('level')}>Level Display</Button>
                <Button color="inherit" onClick={() => setPage('daytypes')}>Day Types</Button>
                </div>
            </Toolbar>
        </>
    );
}

export default TopNavbar;
